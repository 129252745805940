/*

	-----------------------
	OneTrust Cookie Consent
	-----------------------

	DOCS: 
		- Banner SDK JavaScript API: https://developer.onetrust.com/onetrust/docs/javascript-api


	Custom functionality
	--------------------
	- the OptanonWrapper() fires once all files related to the Cookie consent finished download
	- see SDK reference to see what files are downloaded:
	- https://developer.onetrust.com/onetrust/docs/performance-availability-cookie-script

*/
function OptanonWrapper() {
	var cookieBannerContainer = document.getElementById('onetrust-consent-sdk');
	document.body.prepend(cookieBannerContainer);

	// Move Manage button into button group
	var bannerActionsContainer = document.querySelector('.banner-actions-container');
	var bannerBtnHandlerManage = document.getElementById('onetrust-pc-btn-handler');
	// if (bannerActionsContainer && bannerBtnHandlerManage) {
	// 	bannerActionsContainer.append(bannerBtnHandlerManage);
	// }

	// Wrap the Accept button and append the Reject button
	var preferenceBtnHandlerAccept = document.getElementById('accept-recommended-btn-handler');
	var preferenceBtnHandlerReject = document.querySelector('.ot-pc-refuse-all-handler');
	var preferenceBtnVirtualWrapper = createElementWithClass('div', 'virtual-ot-btn-wrapper');
	wrapElement(preferenceBtnHandlerAccept, preferenceBtnVirtualWrapper);
	if (preferenceBtnHandlerReject) {
		preferenceBtnVirtualWrapper.append(preferenceBtnHandlerReject);
	}

	if (!getCookie('OptanonAlertBoxClosed')) {
		// Expand accordion
		var accordionButtons = document.querySelectorAll('.ot-accordion-layout button');
		for (var i = 0; i < accordionButtons.length; i++) {
			accordionButtons[i].setAttribute('aria-expanded', 'true');
		}
	}

	// Handle inital load
	// var cookieBannerOverlay = document.getElementsByClassName('onetrust-pc-dark-filter');
	// var overlayControl = document.querySelector(".overlay-control");
	// if (!overlayControl) {
	// 	if (cookieBannerOverlay[0] && window.getComputedStyle(cookieBannerOverlay[0]).display === 'block')  {
	// 		document.body.classList.add('ot-overflow-hidden');
	// 	} else {
	// 		document.body.classList.remove('ot-overflow-hidden');
	// 	}
	// }


	// // Handle close/submit
	// document.querySelectorAll('#onetrust-accept-btn-handler,.onetrust-close-btn-handler,#accept-recommended-btn-handler,.save-preference-btn-handler, #onetrust-reject-all-handler, .ot-pc-refuse-all-handler').forEach(function(item) {
	// 	item.addEventListener('click', function() {
	// 		document.body.classList.remove('ot-overflow-hidden');
	// 	});
	// });


	function getCookie(name) {
		var value = '; ' + document.cookie;
		var parts = value.split('; ' + name + '=');
		if (parts.length == 2) {
			return parts
				.pop()
				.split(';')
				.shift();
		}
	}

	if (getCookie("OTVariant")) {
		var cookieVariantValue = getCookie("OTVariant");
		dataLayer.push({
			"testId3": cookieVariantValue
		})
	}

	function createElementWithClass(el, className) {
		element = document.createElement('div');
		element.className = className;
		return element;
	}

	function wrapElement(el, wrapper) {
		el.parentNode.insertBefore(wrapper, el);
		wrapper.appendChild(el);
	}


	const gpcEnabled = navigator.globalPrivacyControl;
	if (gpcEnabled) {
        var cookieBanner = document.getElementById("onetrust-consent-sdk");
        cookieBanner.classList.toggle("gpc-detected");
	}

	var gpcOnController = document.querySelector(".gpc-on"),
		gpcOKButton = document.getElementById("cookieGPCControlButton"),
		buttonContainer = document.querySelector(".banner-actions-container")

	if (gpcOnController && gpcOKButton) {
		buttonContainer.appendChild(gpcOKButton);

		if (gpcEnabled) {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.Close();
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
		else {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.AllowAll();
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
	}




	//
	//	Mininalistic Banner Template
	//
	/*
		The function oneTrustMiniBanner() handles following templates
		- AVG – MINIMALISTIC (+ Reject)
		- AVG – MINIMALISTIC (+ Manage)

		DEV notes
		–––––––––
		- The function oneTrustMiniBanner() creates a new HTML template on-the-fly via JavaScript.
		- Text used in the new template extracted from the original OneTrust banner template (<div id="id="onetrust-banner-sdk">).
		- The original OneTrust banner template is hidden via custom CSS (defined in OneTrust admin)
			- if you need to adjust the custom CSS, open one of the following links, click the "Banner" tab, open "Styling" accordion and locate "Custom CSS" textarea

				1. Template
				Name: AVG – MINIMALISTIC (+ Reject)
				Link: https://app-de.onetrust.com/cookies/templates/edit/019204a0-3b96-757b-934f-70890d53f323

				2. Template
				Name: AVG – MINIMALISTIC (+ Manage)
				Link: https://app-de.onetrust.com/cookies/templates/edit/01948389-38e6-78cc-b1a5-8f16e7028090

		- the "otBannerDescription" element
			- exists only in the "AVG – MINIMALISTIC (...)" templates

	*/


	// Get default Cookie Preferences
	// - to see the settings, go to: OneTrust Admin > "Geolocation Rules" > "AVG Consent Policy"
	const oneTrustCookieGroups = OneTrust.GetDomainData().Groups;

	// Default to false
	var isGpcEnabledInOneTrustAdmin = false;

	// Check if current OneTrust template has GPC enabled
	for (var i = 0; i < oneTrustCookieGroups.length; i++) {
		if (oneTrustCookieGroups[i].IsGpcEnabled) {
			isGpcEnabledInOneTrustAdmin = true; // Set to true if at least one group has IsGpcEnabled as true
			break; // Exit the loop early since we found a true value
		}
	}


	// Get country and state code (and convert it to lowercase – on Preview it returns lowercase, on Production uppercase)
	const countryCode = OneTrust.getGeolocationData().country.toLowerCase();	// Example: 'us' (United States)
	const stateCode = OneTrust.getGeolocationData().state.toLowerCase();		// Example: 'ca' (California)
	const locale = countryCode + '-' + stateCode;								// Example: 'us-ca'

	// Get text "This site uses"
	// - this text is defined in OneTrust admin (tab "Banner" > "Content" accordion > "Title & Description" > "Notice description" textarea)
	const otBannerDescription = document.getElementById('oneTrust__minimalisticBanner--text');
	const isMinimalisticBannerUsed = !!otBannerDescription; // Convert otBannerDescription to a boolean: true if the element exists, false if not


	/*

		Specific conditions must be met in order to show the minibanner
		===============================================================

		Common conditions
		-----------------
			- !OneTrust.IsAlertBoxClosed() = Visitor hasn't interacted with the banner
			- isMinimalisticBannerUsed = Minimalistic template is set in OneTrust Admin

		Geolocation & GPC support conditions
		------------------------------------
			- isGroup1 = 🇺🇸 US 🟢 GPC-supported states (or any other country/states which supports GPC)
				- GPC is supported by law in these states…
				- nevertheless, the Banner will be shown only if the browser doesn't transmit the GPC signal (if the browser transmit the GPC signal, no banner is needed)
			- isGroup2 = 🇺🇸 US 🔴 GPC-not-supported state
			- isGroup3 = 🇦🇺 Australia 🔴 GPC-not-supported state

	*/
	const shouldDisplayBanner = !OneTrust.IsAlertBoxClosed() && isMinimalisticBannerUsed;
	const isGroup1 = isGpcEnabledInOneTrustAdmin && !gpcEnabled && shouldDisplayBanner;
	const isGroup2 = !isGpcEnabledInOneTrustAdmin && countryCode === 'us' && shouldDisplayBanner;
	const isGroup3 = countryCode === 'au' && shouldDisplayBanner;


	//
	//	Condition Group
	//
	const otConditionGroup = 
		isGroup1 ? 'Group 1.: 🇺🇸 US 🟢 GPC-supported state' :
		isGroup2 ? 'Group 2.: 🇺🇸 US 🔴 GPC-not-supported state' :
		isGroup3 ? 'Group 3.: 🇦🇺 Australia 🔴 GPC-not-supported state' :
		(isGpcEnabledInOneTrustAdmin && gpcEnabled && shouldDisplayBanner) ? 'Group 4.: 🇺🇸 US 🟢 GPC-supported state && 🟢 GPC signal detected = ❌ Don\'t show the Banner' :
		'Group 5.: Default OneTrust Banner';


	//
	//	DEV only: Debug data
	//
	const otDebugData = {
		'otConditionGroup': otConditionGroup,
		'countryCode': countryCode,
		'stateCode': stateCode,
		'locale': locale,
		'isGpcEnabledInOneTrustAdmin': isGpcEnabledInOneTrustAdmin,
		'isGpcSignalDetected': !!gpcEnabled,	// Does the browser transmit the GPC signal?
		'isMinimalisticBannerUsed': isMinimalisticBannerUsed,
		'IsAlertBoxClosed': OneTrust.IsAlertBoxClosed()
	};

	// Attach the debug method to the OneTrust object
	OneTrust.debug = function () {
		// Version number – to help us track which version is in production
		console.log(
			'%cOneTrustMini Banner - v1.0.5',
			'background: #FF7800; color: #071d2b; padding: 4px 8px; border-radius: 4px; font-size: 14px; font-weight: bold;'
		);
		console.table(otDebugData);
	};


	//
	//	Create the MiniBanner
	//
	function oneTrustMiniBanner() {
		if ( isGroup1 || isGroup2 || isGroup3 ) {
			console.log('OneTrustMini Banner - v1.0.5'); // Please retain the version number to help us track which version is in production.
	
			// The new HTML template for the Cookie Consent Banner will be stored here.
			var otMini;

			// Helper classes
			const bannerExpandedClass = 'js-expanded';
			const bannerHiddenClass = 'js-hidden';
	
			// Check if the page is an Academy page (= "Signal" page - AVG uses different naming)
			const isAcademyPage = /avg\.com\/[^\/]+\/signal(\/.*)?(\?.*)?(#.*)?$/.test(window.location.href);

			// Checks if template with the "Reject" button is used
			const isGpcMinimalisticRejectAllTemplate = OneTrust.GetDomainData().BannerShowRejectAllButton; // Returns true when the "Reject" button is shown


			//
			//	1. Retrieve data from the OneTrust object
			//
			const otDomainData = OneTrust.GetDomainData();

			const otData = {
				// The text is defined in OneTrust admin (tab "Banner" > "Content" accordion > "Title & Description" > "Notice description" textarea)
				text: otBannerDescription.textContent || "This site uses",
			
				cookiePolicyLink: {
					// The text is defined in OneTrust admin (tab "Banner" > "Content" accordion > "Cookie Policy Link" > "Cookie Policy Link" input)
					text: document.querySelector(".ot-cookie-policy-link").textContent || "cookies",
					title: document.querySelector(".ot-cookie-policy-link").getAttribute("aria-label") || "More information about your privacy",
					url: otDomainData.AboutLink || "https://www.avg.com/cookies"
				},
			
				buttons: {
					// The text is defined in OneTrust admin (tab "Banner" > "Content" accordion > "Button set")
					accept: otDomainData.AlertAllowCookiesText || "Accept",
					reject: otDomainData.BannerRejectAllButtonText || "Reject",
					manage: otDomainData.AlertMoreInfoText || "Manage"
				}
			};


			// Test: Data Validation
			/*
			if (otData) {
				console.log("Testing otData values...");
			
				// Create an array of objects to represent the table
				const dataValidationTable = [
					{ Name: "Text", Value: otData.text, Expected: "This site uses" },
					{ Name: "Cookie Policy Link Text", Value: otData.cookiePolicyLink.text, Expected: "cookies" },
					{ Name: "Cookie Policy Link Title", Value: otData.cookiePolicyLink.title, Expected: "More information about your privacy" },
					{ Name: "Cookie Policy Link URL", Value: otData.cookiePolicyLink.url, Expected: "https://www.avg.com/cookies" },
					{ Name: "Accept Button Text", Value: otData.buttons.accept, Expected: "Accept" },
					{ Name: "Reject Button Text", Value: otData.buttons.reject, Expected: "Reject" },
					{ Name: "Manage Button Text", Value: otData.buttons.manage, Expected: "Manage" }
				];
			
				// Log the table
				console.table(dataValidationTable);
			} else {
				console.error("otData is not defined or is null.");
			}
			*/


			//
			// 2. Create OneTrust Mini Banner
			//
			function createOneTrustMiniBanner() {
				//
				// 2.1 Create main container
				//
				otMini = document.createElement('div');
				otMini.id = 'oneTrustMini';
				otMini.tabIndex = 0; // Makes the Banner accessible via keyboard
				
				// Mobile – handle tap on the Cookie icon (Expands the banner)
				otMini.addEventListener('touchend', function (e) {
					// Check if the 'bannerExpandedClass' is already applied
					if (!otMini.classList.contains(bannerExpandedClass)) {
						e.preventDefault(); // Prevent default only if the banner is not expanded (without this, the 'Reject'/'Manage' button was activated when the banner expanded)
						otMini.classList.add(bannerExpandedClass); // Expand the banner
					}
				});

				//
				// 2.2 Create Cookie icon
				//
				const cookieIcon = document.createElement('div');
				cookieIcon.id = 'oneTrustMini__cookie-icon';
				otMini.appendChild(cookieIcon);
	
				//
				// 2.3 Create Content container
				//
				const contentContainer = document.createElement('div');
				contentContainer.id = 'oneTrustMini__content';
				otMini.appendChild(contentContainer);
	
				//
				// 2.3.1 Create Policy Text container
				//
				const policyText = document.createElement('div');
				policyText.id = 'oneTrustMini__text';
	
				// Create the span element for the text
				const span = document.createElement('span');
				span.textContent = otData.text + ' '; // text "This site uses" + add space character after the text
	
				// Create Cookies policy link
				const link = document.createElement('a');
				link.id = 'oneTrustMini__policy-link';
				link.href = otData.cookiePolicyLink.url;
				link.target = '_blank';
				link.title = otData.cookiePolicyLink.title;
				link.textContent = otData.cookiePolicyLink.text;
	
				// Append content to the Text container
				policyText.appendChild(span);
				policyText.appendChild(link);
	
				//
				// 2.3.2 Create Buttons container
				//
				const buttonsDiv = document.createElement('div');
				buttonsDiv.id = 'oneTrustMini__buttons';
	
				// Create Accept button
				const acceptButton = document.createElement('button');
				acceptButton.id = 'oneTrustMini--accept';
				acceptButton.textContent = otData.buttons.accept;
	
				// Handle click on Accept button
				acceptButton.addEventListener('click', function(){
					OneTrust.AllowAll(); // Call OneTrust SDK function
					otMini.classList.add(bannerHiddenClass); // Hide the Banner
				});


				// Create secondary button (Reject / Manage)
				const secondaryButton = document.createElement('button');

				if(isGpcMinimalisticRejectAllTemplate) {
					// Create the "Reject" button (California only)
					secondaryButton.textContent = otData.buttons.reject;

					// Handle click on the Reject button
					secondaryButton.addEventListener('click', function(){
						OneTrust.RejectAll(); // Call OneTrust SDK function
						otMini.classList.add(bannerHiddenClass); // Hide the Banner
					});
				} else {
					// Create the "Manage" button for rest of the US states
					secondaryButton.textContent = otData.buttons.manage;
						
					// Handle click on the Manage button
					secondaryButton.addEventListener('click', function(){
						OneTrust.ToggleInfoDisplay(); // Opens the OneTrust Preference Center
					});
				}

				// Append buttons to the Buttons container
				buttonsDiv.appendChild(acceptButton);
				buttonsDiv.appendChild(secondaryButton);
	
				//
				// 2.3 Append content to the Content container
				//
				contentContainer.appendChild(policyText);
				contentContainer.appendChild(buttonsDiv);
	
				//
				// 2.4 Append the whole Banner to the body
				//
				document.body.appendChild(otMini);

				//
				// 2.5 Preference center – hide the Banner when "Accept all" or "Confirm selection" button is clicked in the Preference center
				//
				const otPcAcceptAllButton = document.getElementById('accept-recommended-btn-handler');	// "Accept all" button
				const otPcConfirmSelectionButton = document.querySelector('.save-preference-btn-handler');	// "Confirm selection" button

				// Handle click on "Accept all" button
				otPcAcceptAllButton.addEventListener('click', function() {
					otMini.classList.add(bannerHiddenClass); // Hide the Banner
				});

				// Handle click on "Confirm selection" button
				otPcConfirmSelectionButton.addEventListener('click', function() {
					otMini.classList.add(bannerHiddenClass); // Hide the Banner
				});
			}
			createOneTrustMiniBanner();
	
	
			//
			//	3. Expand/Collapse OneTrust Mini banner on scroll
			//
			function toggleBannerOnScroll() {
				window.addEventListener('scroll', function () {
					// Get the scroll position
					const scrollPosition = window.scrollY || document.documentElement.scrollTop;
	
					// Expand/Collapse the Banner when scrolled more then 200px from the top
					if (scrollPosition > 200) {
						if (isAcademyPage) {
							// Collapse Banner on Academy pages
							otMini.classList.remove(bannerExpandedClass);
						} else {
							// Expand Banner on all other pages
							otMini.classList.add(bannerExpandedClass);
						}
					}
				});
			}
			toggleBannerOnScroll();


			//
			//	4. Academy pages
			//
			function academyPagesBanner() {
				if (isAcademyPage) {
					// Banner is open by default (and collapsed on scroll)
					otMini.classList.add(bannerExpandedClass);	// expand the Banner

					// Academy pages have a Sticky Bar. Move Cookie Consent Banner above the Sticky Bar
					function adjustCookieConsentBannerPositionOnAcademyPages() {
						const bodyClasses = document.body.className.split(' ');
					
						// Extract platform class from <body> (for example "os-android")
						function getPlatformClass(classes) {
							for (var i = 0; i < classes.length; i++) {
								if (classes[i].startsWith('os-')) {
									return classes[i];
								}
							}
							return null;
						}

						const platformClass = getPlatformClass(bodyClasses);

						//
						//	Get reference to Sticky bars
						//
						/*
							Academy pages are using 2 different sticky bars:
							1. mobileStickyBar – used on Article detail template (example: https://www.avg.com/en/signal/what-is-malware)
							2. desktopStickyBar - used on Article detail template. Visible from 993px and up (example: https://www.avg.com/en/signal/what-is-malware)
						*/
						const mobileStickyBar = document.querySelector('.sticky-post-widget');
						// Get the Sticky bar currently in use (a bar exists for each platform; retrieve the appropriate one)
						const desktopStickyBar = platformClass ? document.querySelector('.sidebar-sticky-wrapper .os-detect.' + platformClass + ' .sidebar-widget') : null;

						// Adjust position of the Cookie Consent Banner
						function adjustCookieConsentBannerMargin(stickyBar, isVisible) {
							// when the Sticky Bar is visible, get its height and set it as margin to the Cookie Consent Banner.
							// when the Sticky Bar NOT visible, reset margin to 0px
							const height = isVisible ? stickyBar.offsetHeight : 0;
							otMini.style.marginBottom = height + 'px';
						}
					
						function checkMobileStickyBarVisibility() {
							// check if the stickyBar exists and it's not hidden via CSS
							const isVisible = mobileStickyBar && window.getComputedStyle(mobileStickyBar).display !== 'none';
							adjustCookieConsentBannerMargin(mobileStickyBar, isVisible);
						}

						function checkDesktopStickyBarVisibility() {
							if (!desktopStickyBar) return;	// check if the stickyBar exists
							const style = window.getComputedStyle(desktopStickyBar);
							// this unique combinations indicates that the stickyBar is sticked to the bottom of the screen and is visible
							const isVisible = style.display === 'flex' && style.position === 'fixed';
							adjustCookieConsentBannerMargin(desktopStickyBar, isVisible);
						}

						// Only one Sticky Bar is visible at a time. Call the appropriate function.
						function checkStickyBarVisibility() {
							const viewportWidth = window.innerWidth;
					
							if (viewportWidth < 993) {
								checkMobileStickyBarVisibility();
							} else {
								checkDesktopStickyBarVisibility();
							}
						}

						// Initial visibility check
						//checkStickyBarVisibility();	// Keep this disabled. It's causing buggy behaviour.
					
						// Re-check visibility on scroll and window resize
						window.addEventListener('scroll', checkStickyBarVisibility);
						window.addEventListener('resize', checkStickyBarVisibility);
					}
					adjustCookieConsentBannerPositionOnAcademyPages();
				}
			}
			academyPagesBanner();
		}
	}
	oneTrustMiniBanner();

}
